<template>
  <div class="terms and policy">
    <h1 class="text-center  mt-200 mb-200 pt-200 pb-200">
      使用者協議
    </h1>
    <p>歡迎使用『BEESUMMER 借貸顧客管理軟體系統』網站，本網站是由『雲祺科技股份有限公司』所經營。您於瀏覽、使用本網站前，請務必完整閱讀本使用者協議之各條款。</p>
    <h2>第一條（用語定義）</h2>
    <ol type="1">
      <li>『BEESUMMER 借貸顧客管理軟體系統』（以下簡稱『本網站』）係指雲祺科技股份有限公司於網路上提供之雲端借貸網站。</li>
      <li>『雲祺科技股份有限公司」（以下簡稱『本公司』）。</li>
      <li>「本協議』係指為使用者協議</li>
      <li>「契約」係指為使用本公司提供之本網站而簽訂之契約。</li>
    </ol>
    <h2>第二條（條款之適用及修訂）</h2>
    <ol type="1">
      <li>本協議適用於全體客戶，您必須同意本協議條款各項規定始得使用本網站。</li>
      <li>當您註冊成為本網站會員，或使用操作本網站時，均視為您已閱讀、瞭解並同意接受暨配合遵守本協議之所有內容及其後修改變更。</li>
      <li>若您不同意本協議之全部或部分者，請您停止瀏覽、使用本網站，本網站條款適用於全體使用者，您必須同意本網站條款各項規定始得使用本網站。</li>
      <li>本網站中若有與本協議其他規定（以下簡稱「其他規定」），則其他規定應視為本協議之一部分。本協議之規定與其他規定有所牴觸時，應以本協議優先適用。</li>
      <li>企業方案之契約人於其認可之企業方案使用者開始使用本網站時，應負責向其說明本網站條款並要求其遵守。</li>
      <li>使用者必須理解並遵守本網站條款內容始得使用本網站。</li>
      <li>本網站條款若有修訂之情形，本公司將要求客戶同意修訂後之服務條款。</li>
      <!-- <li>本公司基於「個人資料保護之相關法令」之規範目的，應盡善良管理人之責任，妥善管理契約人之個人及客戶資料，未經契約人允許，本公司將不會。</li> -->
      <li>如您違反本協議條款，或有下列情事之一者，本公司有權得隨時暫停、限制或終止您使用本網站全部或一部服務之權限，並得暫停、取消或刪除您的會員資格及帳號密碼之使用權限並為任何必要之處置（包括但不限於：將您在本網站內之任何內容加以移除並刪除），且無須取得您的同意、亦無須事前通知，亦不需要對您負任何義務與責任:
        <ol class="list-style-circle">
          <li>未給付與服務項目有關的費用或手續費。</li>
          <li>您有任何造成本網站或第三人財務損失或權益受侵害之情事。</li>
          <li>其他任何本網站有正當理由認為不適當之行為。</li>
          <li>其他任何本網站有正當理由認為不當之行為。</li>
          <li>經司法機關查獲從事任何不法之行為。</li>
        </ol>
      </li>
      <li>如因可歸責於您之事由，造成本網站服務停止或中斷或造成本網站、本公司受有損失時，本網站及本公司得向您請求一切相關之損害賠償。</li>
      <li>如因可歸責於您之事由或您違反本協議條款，致侵害任何第三人之權利，因而衍生或導致任何第三人對本公司、本網站提出任何索賠或請求，則您應對本公司、本網站及該第三人負損害賠償責任 (包括但不限於：訴訟費用、律師費、賠償金、和解金等)。</li>
      <li>您明確瞭解並同意，您使用本網站及本網站服務之一切風險損失，由您個人承擔，本公司不負損害賠償等責任。</li>
      <li>您明確瞭解並同意，本網站應用於正當、合法之業務使用，任何操作本網站之方式，均應符合您所在地（包括但不限於中華民國法律）之法律規定，如有任何利用本網站進行違法業務（包括但不限於重利、詐欺、洗錢等犯罪）使用，均與本公司無涉。</li>
      <li>您明確瞭解並同意：
        <ol class="list-style-circle">
          <li>本公司為維護本網站服務相關系統或軟硬體設備，有權預先規劃而暫停本網站服務之全部或一部，本網站將於暫停服務前進行公告，不另個別通知。但因臨時性、突發性、急迫性、必要性或不可歸責於本網站之事由者，不在此限。</li>
          <li>本網站服務之暫停或中斷，可能造成您使用上的不便、錯誤、資料遺失或其他損失損害，您平時亦應採取適當的防護措施以保障自身權益，本公司毋須負任何法律或賠償責任。</li>
          <li>任何網路上之數據傳送，均無法保證百分之百的安全。您同意使用本網站服務係基於您的個人意願，您並同意自負任何風險，包括因為自本網站服務中獲得、下載之資料軟體導致您的裝置或系統損壞，或是發生任何資料流失，或是存放在本網站伺服器上的資料因不可抗力或軟硬體故障，導致資料損毀或流失等，本公司對於該等損害不負任何賠償責任。</li>
        </ol>
      </li>
      <li>本公司為求完善本網站及本網站服務，有權就本網站服務之全部或一部，因應需求隨時進行修改及另增訂細節性規範，修改及增訂後的本網站服務，於公告後即生效，不另行個別通知，您應留意本網站相關公告事項。且修改後之內容一旦公告，即有效取代先前內容。若您於本網站服務之全部或一部，為任何增訂修改變更後，繼續使用本網站或使用、操作、接受本網站服務，均視為您已閱讀、瞭解並同意接受暨配合遵守該等修改或變更。</li>
      <li>本協議以中華民國法律為準據法。因本協議所生之爭議，如因此涉訟，您與本公司合意以臺灣臺北地方法院為第一審管轄法院。</li>
      <li>本協議及完整規範如有部分條款違法、無效或無法執行者，不影響其他條款之效力。任何延誤、未採取任何行動或未行使權利及規定，均不構成本公司對相關條款或權利的任何放棄。</li>
    </ol>
    <h2>第三條 （本網站之提供）</h2>
    <ol>
      <li>本公司提供本網站予客戶。本網站各方案之服務具體內容，應以本公司另行制定之
        <b-link :to="{ path: '/#pricing-section' }">
          方案比較頁面
        </b-link>規定為準。
      </li>
      <li>客戶應自行承擔責任及費用，設置硬體、軟體、網路連線、確保資訊安全等使用本網站時之必要環境（以下簡稱「使用環境」）。</li>
    </ol>
    <h2>第四條 （智慧財產權）</h2>
    <ol>
      <li>程式、服務提供畫面、本網站相關之所有專利權、新型專利權、新式樣專利權、商標權、著作權、不正競爭防止法（相當於公平交易法）規定之權利，或其他所有一切財產上或人格上之權利（以下簡稱「智慧財產權等」）均屬本公司或該當權利之授權人所有。</li>
      <li>客戶得因簽訂本網站之使用者協議而開始使用本網站，但不等同於取得本網站相關之智慧財產權。客戶不得嘗試對本網站之部分或全部內容進行反向工程、反編譯、反組譯或分析本網站之其他內容。</li>
      <li>本網站及本網站服務所使用軟體或程式、網站上所有內容、電磁紀錄等，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計等，均由本網站或其他權利人依法擁有其權利及智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密等。任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編、反向組譯或為任何違反智慧財產規範之行為。若您欲使用、引用或轉載前述軟體、程式或網站內容，均必須依法取得本網站或其他權利人的事前書面同意。如有違反前開規定，您應對本公司、本網站及其他權利人應負損害賠償責任 (包括但不限於訴訟費用及律師費用等)。</li>
    </ol>
    <h2>第五條 （本協議契約期間）</h2>
    <ol>
      <li>本協議生效及使用開始日期，當您申請使用本網站時，以註冊完成日期作為本公司已受理申請及本協議生效之日。</li>
    </ol>
    <h2>第六條（認證、密碼管理）</h2>
    <ol>
      <li>使用本網站時必須登錄電子郵件地址及密碼。</li>
      <li>客戶應負責嚴格管理已登錄之電子郵件地址及密碼，防止第三方知曉。若察覺密碼已為第三方所知曉時，應立即變更密碼。</li>
      <li>若有人使用客戶已登錄之電子郵件地址及密碼登入並使用本網站時，本公司將視其為完成該些資料登錄之客戶本人的操作行為。</li>
    </ol>
    <h2>第七條（使用費）</h2>
    <ol>
      <li>使用本網站時，契約人應依以下各款規定支付使用費。
        <ol class="list-style-circle">
          <li>服務使用費 本網站之使用費、應依另訂之方案比較頁面規定辦理。</li>
          <li>追加儲存空間使用費僅限依本公司另訂之方式辦理。</li>
          <li>更改企業方案 僅限依本公司另訂之方式辦理。</li>
          <li>付款方式  僅限依本公司另訂之方式辦理。</li>
          <li>使用費之產生  提出各方案之使用及變更，以及追加功能之申請後，依本公司另訂之方式完成付款之日起開始產生使用費。</li>
          <li>付款日 依本公司另訂之方式付款。</li>
        </ol>
      </li>
      <li>契約期間內不論是否有使用之事實，仍應支付契約期間之全額使用費。</li>
      <li>確認使用費尚未付清時，經本公司請求後，契約人應依本公司指定方式立即支付未付款項。若前述款項仍未付清者，本公司得依第十七條規定停止本網站服務之提供或辦理解約手續</li>
    </ol>
    <h2>第八條（通知與聯絡）</h2>
    <ol>
      <li>本公司對客戶之通知得採書面寄送、電子郵件寄送，或公告於本網站等本公司判斷為適當之通訊方式辦理。相關通知若採電子郵件寄送或公告於本網站時，於網路上完成郵件發送或公告時即視同已送達至客戶端。</li>
      <li>客戶對本公司之聯絡應使用本公司認可之聯絡方式。若有採用前述方式以外之手段聯絡者，本公司一律不予受理。</li>
    </ol>
    <h2>第九條 （個人資料處理）</h2>
    <ol>
      <li>本公司依隱私權政策規定妥善處理客戶之個人資料。</li>
    </ol>
    <h2>第十條 （本網站內容之變更）</h2>
    <ol>
      <li>本公司得不經事前通知客戶，就本網站各方案之諸條件、價格，以及本網站部分內容之更改或廢止等，逕行變更本網站之內容。有前述情形時，客戶應予同意。</li>
    </ol>
    <h2>第十一條 （服務之中斷）</h2>
    <span>若有以下各款之任一情形時，本公司得不經事前通知客戶逕行暫時中斷服務之提供。客戶使用本網站前應予以理解。</span>
    <ol>
      <li>本網站之系統須進行定期性或緊急性維護時</li>
      <li>火災、停電、意外事故等因素導致本公司無法提供本網站時</li>
      <li>地震、火山爆發、洪水、海嘯等天災因素導致本公司無法提供本網站時</li>
      <li>戰爭、動亂、暴動、騷動、勞資爭議等因素導致本公司無法提供本網站時</li>
      <li>發生無法預料之技術性問題時</li>
      <li>其他在運用及技術方面，經本公司判斷有暫時中斷本網站之必要</li>
    </ol>
    <h2>第十二條 （因客戶因素導致服務之停止）</h2>
    <ol>
      <li>使用者有任何違反政府法令、本協議、之情形時，本公司得不經事前通知客戶逕行暫時中斷服務之提供。客戶使用本網站前應予以理解。</li>
    </ol>
    <h2>第十三條 （本網站之廢止）</h2>
    <ol>
      <li>本公司得於發生不可避免之事由時，逕行終止本網站契約，或廢止本網站之提供</li>
      <li>若有廢止本網站之情形時，將事前於以適當之方式通知客戶。惟發生緊急狀況或其他不可避免之情形時，不在此限。</li>
    </ol>
    <h2>第十四條 （退費、取消）</h2>
    <ol>
      <li>契約期間內不論是否有使用之事實，仍應支付含解約當月前之全額使用費及工本費的剩餘金額。</li>
    </ol>
    <h2>第十五條 （禁止轉讓）</h2>
    <ol>
      <li>客戶若未取得本公司之事前書面同意，不得將本契約上之地位或本網站衍生之權利義務，轉讓、移轉、設定擔保予第三方，或是進行其他處分。惟本公司就</li>
    </ol>
    <h2>第十六條（禁止事項）</h2>
    <span>本網站之內容已有具體規定者，不在此限。 客戶使用本網站時，不論是否有故意或過失之情形，均不得親自或利用第三方從事以下各款規定之行為。客戶使用本網站時，不論是否有故意或過失之情形，均不得親自或利用第三方從事以下各款規定之行為。</span>
    <ol>
      <li>導致本公司或第三方之權益損失或損害之行為，或恐有該疑慮之行為。</li>
      <li>侵犯第三方之人權或違反公序良俗之行為，或恐有該疑慮之行為。</li>
      <li>詐欺、脅迫等實際犯罪手段、以教唆或煽動犯罪為目的使用本網站等犯罪行為或導致犯罪之行為，或恐有該疑慮之行為。</li>
      <li>極端地造成本公司伺服器過度負荷之使用方式等，妨礙本公司或本網站運作之行為，或恐有該疑慮之行為。</li>
      <li>破壞本公司或本網站信用之行為，或恐有該疑慮之行為。</li>
      <li>對本公司提出不實聲明、登錄之行為。</li>
      <li>透過本網站或與本網站相關，使用、散播電腦病毒等有害程式之行為，或恐有該疑慮之行為。</li>
      <li>違反法令之行為。</li>
      <li>將客戶已登錄之電子郵件地址及密碼交由客戶以外之第三方使用本網站之行為。</li>
      <li>未經本公司事前之書面同意，擅自將本網站之服務契約上之地位出借、轉讓予第三方之行為。</li>
      <li>對本網站之部分或全部內容進行反向工程、反編譯、反組譯，或其他分析本網站之行為。</li>
      <li>協助或參與犯罪組織等（係指幫派、幫派成員、右翼團體、犯罪組織，或其他等同於前述身分者，以下亦同）之維持、營運或經營等，與犯罪組織有任何交流或涉及之行為。</li>
      <li>直接或間接引起，或方便前述各款行為進行之行為。</li>
      <li>其他經本公司判斷為不適當之行為。</li>
    </ol>
    <h2>第十七條（本公司要求解約）</h2>
    <ol>
      <li>客戶若有違反前條各款，或其他本網站條款規定之情形時，不論是否應歸責於契約人，本公司得不經事前通知客戶，立即解除相關之服務契約。</li>
      <li>依前項規定解除相關之服務契約時，已支付之使用費將不予退還。</li>
    </ol>
    <h2>第十八條（契約人要求解約）</h2>
    <ol>
      <li>契約人得於相關契約到期日之前，於本公司規定之方法中提出解約申請，解除本網站之服務契約。契約期間內不論是否有使用之事實，仍應支付含解約當月前之全額使用費及工本費的剩餘金額。</li>
    </ol>
    <h2>第十九條（資料之閱覽、使用、揭露、刪除之同意事項）</h2>
    <ol>
      <li>若有以下各款之任一情形時，本公司得就通訊資料進行閱覽、使用，或揭露予第三方。
        <ol type="i">
          <li>取得契約人同意時</li>
          <li>接獲調查機關之命令、法院之囑託調查等揭露之要求時，或接獲行政機關之揭露要求時</li>
          <li>依法律規定有揭露之義務時</li>
          <li>本公司判斷客戶有違反第十六條各款規定之禁止行為時</li>
          <li>為保護客戶或第三人之生命、身體或其他重要權利而有其必要時</li>
          <li>為進行本網站之維護而有緊急之必要時</li>
          <li>符合以上各款之必要性時</li>
        </ol>
      </li>
      <li>若有以下各款之任一情形時，本公司得刪除全部或部分通訊資料。本公司對於刪除後之通訊資料概不承擔包含恢復通訊資料在內之任何責任
        <ol type="i">
          <li>取得契約人同意時</li>
          <li>本公司判斷客戶有違反第十六條各款規定之禁止行為時</li>
          <li>相關服務契約依第十八條規定因契約人要求解約而終止時</li>
          <li>相關服務契約依第十七條規定因本公司要求解約而終止時</li>
          <li>為保護客戶或第三人之生命、身體或其他重要權利而有其必要時</li>
          <li>為進行本網站之維護而有緊急之必要時</li>
          <li>符合以上各款之必要性時</li>
          <li>本網站依第十三條規定廢止時</li>
          <li>試用方案契約人已三個月以上未登入本網站時</li>
          <li>符合以上各款之必要性時</li>
        </ol>
      </li>
    </ol>
    <h2>第二十條（免責事項）</h2>
    <ol>
      <li>客戶之用戶ID及密碼因遭受第三方使用而發生損害時，不論是否為客戶之故意或過失，本公司概不負責。</li>
      <li>於第十一條規定之服務中斷期間，客戶因無法使用本網站而發生之損害、作業中斷導致之損害、資料遺失產生之損害、使用本網站可能獲得之利益因而無法獲得之損害等，對於使用本網站而發生之損害，不論是否為直接損害、間接損害或實際發生之損害，本公司概不負責，對此客戶應予同意。</li>
      <li>於第十二條規定之服務停止期間，客戶因無法使用本網站而發生之損害、作業中斷導致之損害、資料遺失產生之損害、使用本網站可能獲得之利益因而無法獲得之損害等，對於使用本網站而發生之損害，不論是否為直接損害、間接損害或實際發生之損害，本公司概不負責，對此客戶應予同意。</li>
      <li>依第十三條規定廢止本網站時，客戶因無法使用本網站而發生之損害、作業中斷導致之損害、資料遺失產生之損害、使用本網站可能獲得之利益因而無法獲得之損害等，對於使用本網站而發生之損害，不論是否為直接損害、間接損害或實際發生之損害，本公司概不負責，對此客戶應予同意。</li>
    </ol>
  </div>
</template>

<script>

import {
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  data() {
    return {
    }
  },
  computed: {
  },
}
</script>

<style lang="scss">
</style>
